var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.promotions,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.getPromotionsTotalCount,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('FormToolbar',{attrs:{"title":"Акції"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('PromotionDialog',{attrs:{"is-opened":_vm.dialog,"is-editing":_vm.editedIndex > -1,"edited-item":_vm.editedItem},on:{"yes":_vm.save,"no":_vm.close}}),_c('DeleteDialog',{attrs:{"is-opened":_vm.dialogDelete,"message":"Акцію буде видалено"},on:{"yes":_vm.deletePromotionConfirm,"no":_vm.closeDelete}})]},proxy:true}])})]},proxy:true},{key:"item.id",fn:function({ item }){return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
            name: 'PromotionCard',
            params: { id: _vm.currentCompanyId, promotionId: item.id },
          }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.start_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("getShortDate")(item.start_at))+" "),_c('sup',[_vm._v(_vm._s(_vm._f("getShortTime")(item.start_at)))])]}},{key:"item.expired_at",fn:function({ item }){return [_c('span',{class:{ 'red--text text--lighten-1': _vm.isDateExpired(item.expired_at) }},[_vm._v(" "+_vm._s(_vm._f("getShortDate")(item.expired_at))+" "),_c('sup',[_vm._v(_vm._s(_vm._f("getShortTime")(item.expired_at)))])])]}},{key:"item.type",fn:function({ item }){return [_vm._v(_vm._s(_vm.getPromType(item.type)))]}},{key:"item.is_available_once",fn:function({ item }){return [_vm._v(_vm._s(item.is_available_once ? 'Разова' : 'Постійна')+" ")]}},{key:"item.is_active",fn:function({ item }){return [_c('CircleIndicator',{attrs:{"is-on":item.is_active}})]}},{key:"item.users",fn:function({ item }){return _vm._l((item.users),function(user){return _c('div',{key:user.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
              name: 'UserCard',
              params: { id: _vm.currentCompanyId, userId: user.id },
            }}},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" ")])],1)})}},{key:"item.value_type",fn:function({ item }){return [_vm._v(_vm._s(item.value_type === 1 ? 'грн.' : '%'))]}},{key:"item.devices",fn:function({ item }){return _vm._l((item.devices),function(device,index){return _c('div',{key:index},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'DeviceCard', params: { deviceId: device.id } }}},[_vm._v(" "+_vm._s(device.id)+" ")])],1)})}},{key:"item.service_points",fn:function({ item }){return _vm._l((item.service_points),function(point){return _c('div',{key:point.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
              name: 'ServicePointCard',
              params: { id: _vm.currentCompanyId, servicePointId: point.id },
            }}},[_vm._v(" "+_vm._s(point.name)+" ")])],1)})}},{key:"item.actions",fn:function({ item }){return [_c('TableActions',{on:{"edit":function($event){return _vm.editItem(item)},"delete":function($event){return _vm.deletePromotion(item)},"view":function($event){return _vm.navigateToPromotionCard(item)}}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }